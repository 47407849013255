import React from "react"

import { graphql } from "gatsby"
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import PostBlock from '../components/Pages/Blog/PostBlock'
import BlogMenu from '../components/Pages/Blog/BlogMenu'

export default ({ pageContext, data }) => {
    const { category } = pageContext
    const { edges, totalCount } = data.allMarkdownRemark
    const currentLocale = useIntl().locale
    return (
        <Layout className="blogPage blogCategory">
            <div className="container row">
                <div className="blogCategory__header">
                    <div className="blogCategory__headline">
                        <h1><Link to="/blog">Blog</Link></h1>
                    </div>
                    <BlogMenu category={category} />
                </div>
                <div className="blogCategory__category">
                  
                    <h2>
                        {totalCount}&nbsp;
                        {totalCount === 1 ? 
                            <FormattedMessage defaultMessage="post" id="blog.article" />
                            :
                            totalCount > 4 ?
                                <FormattedMessage defaultMessage="posts" id="blog.articles5" />
                            :
                                <FormattedMessage defaultMessage="posts" id="blog.articles" />
                        }&nbsp;
                        {currentLocale === "en" ?
                            <>
                            <FormattedMessage defaultMessage="in" id="blog.in" /> <span>{category}</span>&nbsp;<FormattedMessage defaultMessage="category" id="blog.category" />
                            </>
                            :
                            <>
                            <FormattedMessage defaultMessage="in" id="blog.in" />  <FormattedMessage defaultMessage="category" id="blog.category" />&nbsp;<span>{category}</span>
                            </>
                        }
                        </h2>
                   
                </div>
                <div className="blogPosts__posts">
                    {edges.map(({ node }, i) =>
                        <PostBlock key={i} post={node} />
                    )}
                </div>

            </div>
            <section className="blogPage__bottomMenu">

            </section>
        </Layout>
    )
}


export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
        group(field: frontmatter___category) {
            edges {
                node {
                    frontmatter {
                        locale
                        category
                    }
                }
            }
        }
      totalCount
      edges {
        node {
            fields {
                slug
            }
            excerpt
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                locale
                category
                featuredImage {
                childImageSharp {
                    fluid(maxWidth: 4000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }   
                }
            }
        }
      }
    }
  }
`