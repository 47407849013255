import React from "react"

import Img from 'gatsby-image'
import { Link, useIntl } from "gatsby-plugin-intl"

export default ({ post }) => {
    const currentLocale = useIntl().locale
    return (
                post.frontmatter.locale === currentLocale &&
                <section className="postBlock">
                    {post.frontmatter.featuredImage &&
                        <Link to={post.fields.slug}>
                            <Img fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
                        </Link>
                    }
                    <span className="postBlock__date">
                            {post.frontmatter.date}
                    </span>
                    <div className="postBlock__content">
                        <h2><Link to={post.fields.slug}>{post.frontmatter.title}</Link></h2>
                        
                        <p>{post.excerpt}</p>
                        <Link to={post.fields.slug}>Read article</Link>
                    </div>

                </section>
           
    )
}