import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link, useIntl, FormattedMessage } from "gatsby-plugin-intl"

import { MenuIcon } from '../../../components/Icons'

export default ({ category }) => {
  const removeDiacritics = str => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }
  const currentLocale = useIntl().locale
  const [openNav, setOpenNav] = React.useState(false)
  return (
    <StaticQuery
      query={graphql`
        {
            categoriesGroup: allMarkdownRemark(limit: 2000) {
              group(field: frontmatter___category) {
                edges {
                  node {
                    frontmatter {
                      category
                      locale
                    }
                  }
                }
              }
            }
          }
        `}
      render={data => (
        <nav

          className={`blogMenu ${openNav ?
            "blogMenu--opened"
            :
            ""
            }`}>
          <div
            
            className="blogMenu__headline"
            onClick={() => setOpenNav(!openNav)}
            onKeyDown={() => setOpenNav(!openNav)}
            role="button"
            tabIndex={0}>
            {category ?
              category
              :
              <FormattedMessage
                id="blog.categories"
                tagName="h2"
                defaultMessage="Blog categories"
              />
            }

            <MenuIcon />
          </div>

          <div className="blogMenu__menu">
            {data.categoriesGroup.group.map(({ edges }) =>
              
                  edges[0].node.frontmatter.locale === currentLocale &&
                  <Link to={`/blog/${edges[0].node.frontmatter.category && removeDiacritics(edges[0].node.frontmatter.category)}`}>{edges[0].node.frontmatter.category}</Link>
            )}
            {category && <Link to="/blog"><FormattedMessage
              id="blog.allPosts"
              tagName="h2"
              defaultMessage="All posts"
            /></Link>}
          </div>


        </nav>
      )}
    />
  )
}
